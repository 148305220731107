<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition,
  },
};
</script>

<style>

.display-1 {
  font-size: 60px !important;
}

.lbl-main {
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
}

.lbl-main-caption {
  font-weight: 400 !important;
}

.lbl-small {
  text-transform: uppercase;
  color: #4E5152;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 1px;
  text-align: left;
}

.navbar-toggler-icon {
  padding-left: 32px;
  position: relative;
}
.navbar-toggler-icon:before {
  position: absolute;
  font-family: "FontAwesome";
  top: 5px;
  left: 10px;
  content: "\f0c9";
  color: #474747;
}



.lbl-section-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
}

.app-link {
  color: #197cbd;
  font-size: 14px;
}

.section {
  padding: 20px;
}

.error {
  display: block;
  color: #f57f6c;
  font-size: 0.75rem;
  line-height: 1;
  margin-left: 14px;
  margin-bottom: 2px;
}

/**
  Product related formatting
*/

.lbl-header {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.lbl-section {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
