<template>
  <section>
    <div
      class="row no-gutters align-items-center"
      id="pallet-leasing-and-sales"
    >
      <div class="col-md-6">
        <img src=img/our-competencies/pallets-leasing.png class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Pallets Leasing & Sales</h2>
        <div>
          <p>
            Focused on providing competitive pallet rental packages, technical
            solutions and after-sales services to clients.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center"
      id="precast-concrete-construction"
    >
      <div class="col-md-6 first">
        <h2>Precast Concrete Construction</h2>
        <div>
          <p>
            We offer precast fence and alternative building systems that aim to
            create more value for clients, through innovation, process
            efficiency, and product improvements.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 second">
        <img src=img/our-competencies/precast-concrete.svg class="image"/>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center"
      id="asset-management-solutions"
    >
      <div class="col-md-6">
        <img src=img/our-competencies/asset-management.png class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Asset Management Solutions</h2>
        <div>
          <p>
            We have an intuitive asset monitoring system that utilizes QR CODES
            and CLOUD TECHNOLOGY to transfer and track the accountability of RPE
            (Returnable Packaging Equipment) even in a fast-paced environment in
            your supply chain.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="row align-items-center" id="digital-logging-system">
        <div class="col-md-6 first">
          <h2>Digital Logging System</h2>
          <div>
            <p>
              An automated logging system that uses minimal resources for
              attendance logging. With the power of android phones and QR codes,
              having a touchless attendance system is easy peasy with eLog.
            </p>
            <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
          </div>
        </div>
        <div class="col-md-6 second">
          <img src=img/our-competencies/digital-logging.svg class="image"/>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="security-solutions">
      <div class="col-md-6 second">
        <img src=img/our-competencies/security-solutions.svg class="image"/>
      </div>
      <div class="col-md-6 first">
        <h2>Security Solutions</h2>
        <div>
          <p>
            Experience heightened security with our FDAS, Surveillance System,
            Electrical Fencing and other security solutions.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center"
      id="warehousing-software-solutions"
    >
      <div class="col-md-6 first">
        <h2>Customized Warehousing Software Solutions</h2>
        <div>
          <p>
            Our system supports scheduled and efficient processing of all
            logistics processes within the warehouse.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 second">
        <img src=img/our-competencies/customized-warehousing.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="it-solutions-hardware">
      <div class="col-md-6 second">
        <img src=img/our-competencies/it-solutions.svg class="image"/>
      </div>
      <div class="col-md-6 first">
        <h2>IT Solutions / Hardware</h2>
        <div>
          <p>
            We offer IT Solutions that can be of great help to your existing
            Hardware systems.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="events">
      <div class="col-md-6 first">
        <h2>Events</h2>
        <div>
          <p>
            La Tierra Nature Escape is a sprawling 40-hectare property in the
            heart of Pagbilao, Quezon province. A place to RESET, RELAX, and
            RECHARGE.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 second">
        <img src=img/our-competencies/events.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="agro-tourism">
      <div class="col-md-6 second">
        <img src=img/our-competencies/eco-tourism.svg class="image"/>
      </div>
      <div class="col-md-6 first">
        <h2>Agro-Tourism</h2>
        <div>
          <p>
            Travel is no longer about merely what you see, hear, and taste but
            more about how the experiences you discover change you.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 100px;
  margin-bottom: -90px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 18px;
  margin-left: 100px;
  margin-right: 50px;
  color: #4e5152;
  text-align: justify;
}
.image {
  width: 100%;
}
.button {
  font-family: "TT Commons", sans-serif;
  background-color: white;
  border-color: #a7adaf;
  border-radius: 0;
  font-weight: bold;
  font-size: 10px;
  color: #07529c;
  margin-left: 100px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  section{
    margin-left: 60px;
    margin-right: 60px;
  }
  h2 {
    margin-left: 0px;
    font-size: 20px;
  }
  p {
    margin: 0px;
    font-size: 14px;
  }
  .button {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .first {
    order: 2;
  }
  .second {
    order: 1;
  }
}
</style>
