<template>
  <section>
    <div>
      <img src="img/our-competencies/our-competencies.png" class="image-banner" />
    </div>
    <div>
      <h2>
        High Quality and Convenient
      </h2>
      <p>
        We make sure that every pallet we produce is way above the minimum
        quality requirement for wooden pallets. We perform extensive research
        and multiple pallet testings (testing to destruction, load testing,
        maximum working load testing, and durability comparison testing) to
        ensure its high-performance level before launching any product.
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  margin-top: -10%;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  text-align: justify;
}
@media only screen and (max-width: 600px) {
  .title{
    font-size: 24px;
    top: 21%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
}
</style>
