<template>
  <section>
    <div class="row row-grid" id="tree-planting">
      <div class="col-md-3">
        <h2>Tree Planting</h2>
      </div>
      <div class="col-md-9">
        <p>
          <br /><br />
          The foundation have also embarked on tree planting. With a target of
          200,000 seedlings annually, not only does it benefit the environment
          but the planters as well in terms of income they generate when the
          trees mature and are harvested.
        </p>
        <img src=img/sustainability/tree-planting.svg class="image"/>
        <p>
          It establishes tree nurseries to supply free seedlings to qualified
          farmers or farm tenants residing in Quezon province. It teaches the
          dones how to plant and care for trees to ensure their survival and
          fast growth until the harvest period. Quarterly visits to plantation
          sites and replanting is also a part of the program.
        </p>
        <p>
          These two projects constitutes the Group’s corporate social
          responsibility (CSR) projects which the founders believe must be done
          with passion and dedication, in the same way, the member companies
          were nurtured and grown as going concern organizations as they are
          now.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 100px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 18px;
  color: #4e5152;
  text-align: justify;
}
.image {
  width: 100%;
}
.button {
  font-family: "TT Commons", sans-serif;
  background-color: white;
  border-color: #a7adaf;
  border-radius: 0;
  font-weight: bold;
  font-size: 10px;
  color: #07529c;
  margin-left: 50px;
}
@media only screen and (max-width: 600px) {
  section {
    margin: 50px;
  }
  h2 {
    font-size: 20px;
    margin-bottom: -100px;
  }
  p {
    font-size: 14px;
  }
}
</style>
