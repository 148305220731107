<template>
  <div>
    <Hero/>
    <OurCompanies/>
  </div>
</template>

<script>
import Hero from '@/views/companies/Hero'
import OurCompanies from '@/views/landing/OurCompanies';

export default {
    components: {
      Hero,
      OurCompanies
    }
}
</script>

<style scoped>

</style>