<template>
  <div>
    <Hero />
    <ECRPallet />
    <CartonPallet />
    <EcoPallet />
    <CollapsiblePallet />
    <PlasticPallet />
  </div>
</template>

<script>
import Hero from "@/views/products/Hero";
import ECRPallet from "@/views/products/ECRPallet";
import CartonPallet from "@/views/products/CartonPallet";
import EcoPallet from "@/views/products/EcoPallet";
import CollapsiblePallet from "@/views/products/CollapsiblePallet";
import PlasticPallet from "@/views/products/PlasticPallet";

export default {
  components: {
    Hero,
    ECRPallet,
    CartonPallet,
    EcoPallet,
    CollapsiblePallet,
    PlasticPallet,
  },
};
</script>

<style scoped>
.lbl-main {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
}
</style>