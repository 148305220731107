import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Landing from "./views/Landing.vue";
import AboutUs from "./views/AboutUs.vue";
import Industries from "./views/Industries.vue";
import OurCompetencies from "./views/OurCompetencies.vue";
import OurVacancies from "./views/OurVacancies.vue";
import Sustainability from "./views/Sustainability.vue";
import OurPeople from "./views/OurPeople.vue";
import ContactUs from "./views/ContactUs.vue";
import InnovationAndQuality from "./views/InnovationAndQuality.vue";
import Blogs from "./views/Blogs.vue";
import LaTierra from "./views/LaTierra.vue";
import Tawi25thAnniversary from "./views/Tawi25thAnniversary.vue";
import MindanaoEFCandBeyond from "./views/MindanaoEFCandBeyond.vue";
import MentalHealthAwareness from "./views/MentalHealthAwareness.vue";
import Easyhomes from "./views/Easyhomes.vue";
import KahoyAndCo from "./views/KahoyAndCo.vue";
import TransAsiaWood from "./views/TransAsiaWood.vue";
import Products from "./views/Products.vue";
import Companies from "./views/Companies.vue";
import Page404 from "./views/404";
import CSR from "./views/landing/CSR.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
    },
    {
      path: "/404",
      name: "404",
      meta: { isPublic: true },
      component: Page404,
      alias: "*",
    },
    {
      path: "/about-us",
      name: "about-us",
      components: {
        header: AppHeader,
        default: AboutUs,
        footer: AppFooter,
      },
    },
    {
      path: "/industries",
      name: "industries",
      components: {
        header: AppHeader,
        default: Industries,
        footer: AppFooter,
      },
    },
    {
      path: "/our-competencies",
      name: "competencies",
      components: {
        header: AppHeader,
        default: OurCompetencies,
        footer: AppFooter,
      },
    },
    {
      path: "/our-people",
      name: "people",
      components: {
        header: AppHeader,
        default: OurPeople,
        footer: AppFooter,
      },
    },
    {
      path: "/csr",
      name: "csr",
      components: {
        header: AppHeader,
        default: CSR,
        footer: AppFooter,
      },
    },
    {
      path: "/our-vacancies",
      name: "vacancies",
      components: {
        header: AppHeader,
        default: OurVacancies,
        footer: AppFooter,
      },
    },
    {
      path: "/innovation-and-quality",
      name: "innovation",
      components: {
        header: AppHeader,
        default: InnovationAndQuality,
        footer: AppFooter,
      },
    },
    {
      path: "/sustainability",
      name: "sustainability",
      components: {
        header: AppHeader,
        default: Sustainability,
        footer: AppFooter,
      },
    },
    {
      path: "/products",
      name: "products",
      components: {
        header: AppHeader,
        default: Products,
        footer: AppFooter,
      },
    },
    {
      path: "/companies",
      name: "companies",
      components: {
        header: AppHeader,
        default: Companies,
        footer: AppFooter,
      },
    },
    {
      path: "/contact-us",
      name: "contactUs",
      components: {
        header: AppHeader,
        default: ContactUs,
        footer: AppFooter,
      },
    },
    {
      path: "/blogs",
      name: "blogs",
      components: {
        header: AppHeader,
        default: Blogs,
        footer: AppFooter,
      },
    },
    {
      path: "/la-tierra",
      name: "laTierra",
      components: {
        header: AppHeader,
        default: LaTierra,
        footer: AppFooter,
      },
    },
    {
      path: "/tawi-25th-anniversary",
      name: "tawi25thAnniversary",
      components: {
        header: AppHeader,
        default: Tawi25thAnniversary,
        footer: AppFooter,
      },
    },
    {
      path: "/mindanao-efc-and-beyond",
      name: "mindanaoEfcAndBeyond",
      components: {
        header: AppHeader,
        default: MindanaoEFCandBeyond,
        footer: AppFooter,
      },
    },
    {
      path: "/mental-health-awareness",
      name: "mentalHealthAwareness",
      components: {
        header: AppHeader,
        default: MentalHealthAwareness,
        footer: AppFooter,
      },
    },
    {
      path: "/company-spotlight-easyhomes",
      name: "easyhomes",
      components: {
        header: AppHeader,
        default: Easyhomes,
        footer: AppFooter,
      },
    },
    {
      path: "/kahoy-and-co",
      name: "KahoyAndCo",
      components: {
        header: AppHeader,
        default: KahoyAndCo,
        footer: AppFooter,
      },
    },
    {
      path: "/company-spotlight-trans-asia-wood",
      name: "TransAsiaWood",
      components: {
        header: AppHeader,
        default: TransAsiaWood,
        footer: AppFooter,
      },
    },
    {
      path: "/tawi-pallets",
      name: "products",
      components: {
        header: AppHeader,
        default: Products,
        footer: AppFooter,
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
