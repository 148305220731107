<template>
  <div>
    <Hero />

  </div>
</template>

<script>
import Hero from "@/views/innovation-and-quality/Hero";



export default {
  components: {
    Hero
}
};
</script>

<style scoped>
.lbl-main {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
}
</style>