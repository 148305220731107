import { db } from '@/config/firebase';
import _ from 'lodash';

async function addInquiry(inquiry) {
    const inquiryRef = db.collection('inquiries').doc();
    await inquiryRef.set(inquiry);
    return inquiryRef.id;
}

export default {
    addInquiry
}