<template>
  <div>
    <GetInTouch/>
    <OurLocations/>
  </div>
</template>

<script>
import GetInTouch from './contact-us/GetInTouch.vue';
import OurLocations from './contact-us/OurLocations.vue';

export default {
    components: {
    GetInTouch,
    OurLocations
}
}
</script>

<style scoped>

</style>