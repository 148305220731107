<template>
  <section>
    <div class="row row-grid">
      <div class="d-flex align-items-center mb-4">
        <div class="col-md-3" id="core-values">
          <h2>Core Values</h2>
        </div>
        <div class="col-md-9">
          <p>
            <br />
            TAWI's Core Values are the principles that are of central importance
            in our organization. It also covers what we believe are important when
            it comes to our personal and professional lives.
          </p>
        </div>
      </div>
      <div class="col-md-12">
        <img src="img/about-us/core-values.svg" class="image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100%;
  width: 100%;
  margin-top: -10%;
}
.title {
  font-family: "TT Commons", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 25%;
  width: 100%;
  text-align: left;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4e5152;
  text-align: center;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  margin-top: 20px;
  text-align: justify;
  padding-right: 100px;
}
.image{
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  }
  @media only screen and (max-width: 600px) {
  .title{
    font-size: 24px;
    top: 21%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
    margin-top: -70px;
  }
  .image{
    margin-top: -50px;
    width: 100%;
  }
}
</style>
