<template>
  <section>
    <div class="row no-gutters align-items-center" id="retail-food-and-beverages">
      <div class="col-md-6 first">
        <h2>Retail Food & Beverage</h2>
        <div>
          <p>
            You will find our ECR pallets in food and beverage production
            facilities, warehouses, and a wide range of other industry supply
            chain.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 second">
        <img src=img/industries/retail-food-beverages.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="industrial-parks">
      <div class="col-md-6">
        <img src=img/industries/industrial-parks.svg class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Industrial Parks</h2>
        <div>
          <p>
            You can be sure that from establishment of partnership to
            installation, we are with you all the way.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="events">
      <div class="col-md-6 third">
        <h2>Events</h2>
        <div>
          <p>
            La Tierra Nature Escape is a sprawling 40-hectare property in the
            heart of Pagbilao, Quezon province. A place to RESET, RELAX, and
            RECHARGE.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 fourth">
        <img src=img/industries/events.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="real-estate-developments">
      <div class="col-md-6">
        <img src=img/industries/real-estate-developments.svg class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Real Estate Developments</h2>
        <div>
          <p>
            EHI Precast Fence system may be installed on top of riprap, existing
            CHB wall, with benching and/or sloping terrains.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="construction-contractors">
      <div class="col-md-6 fifth">
        <h2>Construction Contractors</h2>
        <div>
          <p>
            We are one of the most trusted manufacturers and installers of
            concrete precast fence products in the country.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 sixth">
        <img src=img/industries/construction-contractors.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="logistics">
      <div class="col-md-6">
        <img src=img/industries/logistics.png class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Logistics</h2>
        <div>
          <p>
            We aid in palletized deliveries between different entities in the
            supply chain.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="infrastructure-projects">
      <div class="col-md-6 seventh">
        <h2>Infrastructure Projects</h2>
        <div>
          <p>
            At Easyhomes, we never sacrifice quality – we have a philosophy of
            getting the job done right on time and within budget.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
      <div class="col-md-6 eight">
        <img src=img/industries/infrastructure-projects.svg class="image"/>
      </div>
    </div>
    <div class="row no-gutters align-items-center" id="eco-tourism">
      <div class="col-md-6">
        <img src=img/industries/eco-tourism.svg class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Eco-Tourism</h2>
        <div>
          <p>
            Travel is no longer about merely what you see, hear, and taste but
            more about how the experiences you discover change you.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 100px;
  margin-bottom: -100px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  color: #4e5152;
  margin-left: 50px;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 18px;
  margin-left: 100px;
  margin-right: 50px;
  color: #4e5152;
  text-align: justify;
}
.image {
  width: 100%;
}
.button {
  font-family: "TT Commons", sans-serif;
  background-color: white;
  border-color: #a7adaf;
  border-radius: 0;
  font-weight: bold;
  font-size: 10px;
  color: #07529c;
  margin-left: 100px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  section{
    margin-left: 60px;
    margin-right: 60px;
  }
  h2{
    margin-left: 0px;
    font-size: 20px;

  }
  p{
    margin: 0px;
    font-size: 14px;
  }
  .button{
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .first{
    order: 2;
  }
  .second{
    order: 1;
  }
  .third{
    order: 2;
  }
  .fourth{
    order: 1;
  }
  .fifth{
    order: 2;
  }
  .sixth{
    order: 1;
  }
  .seventh{
    order: 2;
  }
  .eight{
    order: 1;
  }
}
</style>
