<template>
  <div class="col-md-6 mb-5 mb-md-0 center">
    <h1 class="lbl-section-name mb-4">
      We're here for you
    </h1>
    <div class="radio">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
        <label class="form-check-label" for="inlineRadio1">Request a site visit</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
        <label class="form-check-label" for="inlineRadio2">General Inquiry</label>
      </div>
    </div>
    <b-form id="contact-us" @submit="onSubmit" @reset="onReset" v-if="show">
      <b-row class="my-3">
        <b-col sm="6">
          <b-form-group>
            <b-form-input id="fName" v-model="form.fName" placeholder="First Name" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input id="lName" v-model="form.lName" placeholder="Last Name" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input id="email" v-model="form.email" type="email" placeholder="Email" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input id="contactNo" v-model="form.contactNo" type="number" placeholder="09XXXXXXXXX"
              required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group>
            <b-form-input id="subject" v-model="form.subject" placeholder="Subject" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group>
            <b-form-input id="company" v-model="form.company" placeholder="Company" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group>
            <b-form-input id="location" v-model="form.location" placeholder="Address" required />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group>
            <b-form-textarea id="message" v-model="form.message" placeholder="Message" rows="3" required
              maxlength="1500" />
          </b-form-group>
        </b-col>
        <b-col sm="12 mt-1">
          <b-button type="submit" class="app-button">Submit</b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import config from "@/config/env-constants";
import api from "@/database/inquiry.js";

export default {
  data() {
    return {
      companyEmail: config.companyEmail,
      companyContactNo: config.companyContactNo,
      form: {
        fName: '',
        lName: '',
        email: '',
        contactNo: '',
        subject: '',
        company: '',
        location: '',
        message: '',
      },
      show: true,
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      let inquiry = Object.assign({}, this.form);
      inquiry.inquiryId = this.generateCustomID();
      inquiry.status = "PENDING";
      inquiry.dateCreated = new Date().getTime();
      inquiry.source = "tawi.com.ph";

      let result = await api.addInquiry(inquiry);
      if (!_.isEmpty(result)) {
        this.$toaster.success(
          "Your inquiry was successfully sent. Please wait for reply from our customer representative."
        );
        this.onReset(event);
      } else {
        this.$toaster.error(
          "There is a problem sending your inquiry. Please try again later."
        );
      }
    },
    generateCustomID() {
      return "TAWI" + Date.now();
    },
    onReset(event) {
      event.preventDefault();

      // Reset our form values
      this.form = {
        fName: '',
        lName: '',
        email: '',
        contactNo: '',
        subject: '',
        company: '',
        location: '',
        message: '',
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");

.company-email {
  margin-top: -12px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #197cbd;
}

.app-button {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  background: #07529c !important;
  color: #ffffff !important;
  height: 10px;
  width: 180px;
  height: 40px;
  float: right !important;
}

.form-group {
  font-size: 12px;
  color: #197cbd;
  font-weight: bold;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-family: "TT Commons", sans-serif;
  text-align: center;
}

::placeholder {
  font-family: "TT Commons", sans-serif;
  font-size: 12px;
}

.radio {
  text-align: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
</style>
