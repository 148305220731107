import { render, staticRenderFns } from "./IndustriesWeServe.vue?vue&type=template&id=231a1d92&scoped=true&"
import script from "./IndustriesWeServe.vue?vue&type=script&lang=js&"
export * from "./IndustriesWeServe.vue?vue&type=script&lang=js&"
import style0 from "./IndustriesWeServe.vue?vue&type=style&index=0&id=231a1d92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231a1d92",
  null
  
)

export default component.exports