<template>
  <div>
    <Hero />
    

  </div>
</template>

<script>
import Hero from "./our-people/Hero.vue";


export default {
  components: {
    Hero
   
},
};
</script>

<style scoped>
</style>