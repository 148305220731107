<template>
  <section>
    <div>
      <img
        src="img/our-people/innovation-and-quality.png"
        class="image-banner"
      />
    </div>
    <div class="row row-grid">
      <div class="col-md-12">
        <h2>A Better, Smarter Future</h2>
      </div>
      <div class="col-md-12">
        <p>
          Here in TAWI, we take pride in bringing the best quality service that
          we can provide. We conduct our business with high standards and TAWI
          is committed in giving our clients the service that we can surely
          deliver.
        </p>
        <p>
          With our after sales service, we can assure our clients that our
          relationship does not just end after signing the contracts, it starts
          there.
        </p>
        <p>
          All services we provide are certified and accredited by our government
          to make sure that we keep up with all the requirements needed for us
          to serve better.
        </p>
        <p>
          When it comes to pallets, We offer Total Pallet Management, On-site
          Repairs, and Pallet Care Training. Even if we operate in multiple
          locations, all of our warehouses complies with all requirements and
          standards.
        </p>
        <p>
          For Pallet Care, we just got our accreditation for our heat treatment
          facility renewed and we are accredited by the Bureau of Plant and
          Industries (BPI) as a HEAT TREATMENT PROVIDER. Heat Treatment is one
          of the treatment processes for Wood Packaging Materials required for
          ISPM 15 or International Standard for Phytosanitary Measure; 15 is the
          15th measure to protect humans, animals, and plants from disease,
          pests, and contaminants.
        </p>
        <p>
          When in comes to innovation, Easyhomes Inc. now offers precast fence
          with E-Fence (Electric Fence) where we offer a variety of wires to
          address different electric fencing requirements. The type of wire
          needed will depend on environmental factors, mechanical strength and
          electrical resistance.
        </p>
        <p>
          Collaboration and consistency are important in starting a project from
          bidding to successful execution. We do this by being open to
          collaborate on other precast projects.
        </p>
        <p>
          We listen, we learn, and we upgrade our services and quality
          constantly.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  margin-top: -10%;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  margin-left: 50px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  margin-left: 300px;
  margin-right: 50px;
  text-align: justify;
}
@media only screen and (max-width: 600px) {
  section{
margin: 0px;
  }.title{
    font-size: 24px;
    top: 12%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
}
</style>
