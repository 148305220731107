<template>
  <div>
    <Hero />
    <OurStory/>
    <TawiGroupHoldings/>
    <IndustriesWeServe/>
    <OurClients />
  </div>
</template>

<script>
import Hero from "./landing/Hero.vue";
import OurStory from "./landing/OurStory.vue";
import IndustriesWeServe from "./landing/IndustriesWeServe.vue";
import OurClients from "./landing/OurClients.vue";
import TawiGroupHoldings from "./landing/TawiGroupHoldings.vue";

export default {
  name: "home",
  components: {
    Hero,
    OurStory,
    IndustriesWeServe,
    TawiGroupHoldings,
    OurClients,
    TawiGroupHoldings
},
};
</script>

<style scoped>
</style>
