<template>
  <section>
    <div>
      <img src="img/our-people/our-vacancies.png" class="image-banner" />
    </div>
    <div class="row row-grid">
      <div class="col-md-12">
        <h2>We're Hiring</h2>
      </div>
      <div class="col-md-12">
        <li>Motorpool Coordinator</li>
        <li>Accounting Staff - AP Specialist</li>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section{
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  margin-top: -10%;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
}
li{
font-family: "TT Commons", sans-serif;
color: #4E5152;
text-indent: 70px;
}
</style>
