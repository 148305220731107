<template>
  <section>
    <div>
      <img src="img/our-people/our-people.png" class="image-banner" />
    </div>
    <div class="row row-grid">
      <div class="col-md-12" id="employee-testimonials">
        <h2>Employee Testimonials</h2>
      </div>
      <div>
        <div class="row">
          <div class="col-md-2">
            <img src="img/our-people/jojo-de-silva.svg" class="image" />
          </div>
          <div class="col-md-10">
            <p>
              I'm a "Balik-TAWI". I returned to Tawi because I have an
              unfinished business to fulfill. Previously, I also worked with a
              family-owned corporation but with TAWI, I've felt the importance
              they gave to every employee of the organization. Voices are heard
              and given considerations.
            </p>
            <p>Jojo de Silva</p>
            <h6>Comptroller</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <img src="img/our-people/ronald-apolonio.svg" class="image" />
          </div>
          <div class="col-md-10">
            <p>
              TAWI values relationships they have with their people. It has good
              management procedures, company culture, and a healthy working
              environment among colleagues. The management leads the various
              teams collectively to the path of success.
            </p>
            <p>Ronald Apolonio</p>
            <h6>Sales Manager</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <img src="img/our-people/dec-ravillosa.svg" class="image" />
          </div>
          <div class="col-md-10">
            <p>
              What I appreciate most about my job and TAWI, are the people that
              I work with. I am also given the freedom to design a process that
              works best for me and my team. I love that company gives us the
              opportunity to continuously learn and grow.
            </p>
            <p>Dec Ravillosa</p>
            <h6>Plantation and Special Projects Coordinator/OIC at TPTP</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <img src="img/our-people/arrcoss-vargas.svg" class="image" />
          </div>
          <div class="col-md-10">
            <p>
              First few months were tough for me but TAWI has good management
              and convinced me that I have a mission to fulfill in this company.
            </p>
            <p>Arrcos Vargas</p>
            <h6>Jr. Business Analyst</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <img src="img/our-people/gary-marasigan.svg" class="image" />
          </div>
          <div class="col-md-10">
            <p>
              Here at TAWI/EHI, I am given the free will to think and strategize
              for my team. I am also able to learn and develop my management
              skills through constant collaboration across departments.
            </p>
            <p>Gary Marasigan</p>
            <h6>Construction Project Manager</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
  margin-top: -5%;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
}
.title {
  font-family: "TT Commons", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: left;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  margin-top: 15px;
  text-align: justify;
}
h6 {
  font-family: "TT Commons", sans-serif;
  margin-top: -20px;
  font-weight: bold;
  color: #4E5152;
}
.image {
  width: 100%;
}
.row {
  margin-top: 50px;
  margin-left: 30px;
  margin-right: 30px;
}
@media only screen and (max-width: 600px) {
  section{
margin: 0px;
  }.title{
    font-size: 24px;
    top: 20%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
    margin-top: 10px;
  }
  .image{
    width: 30%;
  }
  h6{
    font-size: 14px;
  }
}
</style>
