<template>
  <section id="csr" class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-1">
          <img
            src="img/background/tawi-group-of-companies-corporate-social-responsibility-scholarship-program-thumbnail.jpg"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6 order-md-2">
          <div class="pr-md-5">
            <div class="lbl-small">CORPORATE SOCIAL RESPONSIBILITY</div>
            <h2 class="lbl-main">A Better Future</h2>
            <p class="lbl-main-caption">
              The foundation creates scholarship programs for qualified and
              deserving children of underprivileged families. It creates
              platforms for pre-employment training in their chosen fields,
              courtesy of the member companies of the Group.
            </p>
            <a href="/csr" class="app-link">
              Learn more
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.adjust-top-margin {
  margin-top: -100px;
}

.background-image {
  background-color: #f8f9fa;
}
</style>