<template>
  <section>
    <div id="message-from-the-founder">
      <h2>Message from the Founder</h2>
    </div>
    <div class="row row-grid">
      <div class="col-md-3">
        <img src="img/about-us/CEA.png" class="image" />
        <h4>Celso E. Abastillas</h4>
        <h4><b>Chairman/CEO</b></h4>
      </div>
      <div class="col-md-9">
        <input type="checkbox" id="check" />
        <p>
          The Best Years To Come
        </p>
        <p>
          How blessed we are that we were not affected too much by Covid 19
          pandemic. We deal in pallets that are used in transporting foods and
          other essentials. So instead of scaling or shutting down like many in
          the hospitality businesses, we were on business as usual. Our fence
          business was not much affected too except for projects where clients
          are very strict on health protocols like requiring vaccination cards.
          Or there were clients that limited the number of people working in
          their areas to minimize if not avoid contamination.
        </p>
        <p>
          Our trees and coconut plantations, were not affected either. In fact
          we’re able to increase the number of trees planted and harvested as
          some people displaced in Easyhomes were transferred to plantations,
          thereby increasing its production.
        </p>
        <p>
          We had already nailed 2 clients for Tawitech, Pakil municipality for
          E-log and PJ Lhullier for Ayun. We currently have 3 or 4 more clients
          in the pipeline, so that will make the staff busy during the year.
        </p>
        <p>
          The economy in 2022 is projected to grow 7-7.5% which will make the
          Philippines one among the fastest growing economies in Asia and even
          in the world. The same is true for 2023, when our economy will be
          growing almost the same as in 2022 if not higher.
        </p>
        <div class="content">
          <p>
            Tawi Group is also upbeat for the year 2023. Our pallet rental
            business is doing very well. The problem now is how to supply rather
            than how to close sales, which to us is a better problem. We are
            scouring the whole country for wood supply, on top of the lumber
            supplied by our own plantations. We are not discounting, though, the
            possibility of importing again from Eastern Europe should the local
            sources are not able to supply the current requirements.
          </p>
          <p>
            The events and restaurant business is doing well too. Thanks to its
            location which is very near the market it is serving. The building
            looks brand new now, thanks to its manager who initiated is
            repainting. The business is enjoying the “revenge spending”, which
            Filipinos are now doing after the almost 3 years pandemic lockdown.
          </p>
          <p>
            Indeed, the future looks very bright for the Philippines and Tawi
            Group of Companies. We are expanding our pallet operations in Cebu
            as well as in Quezon on our restaurant/resort business. In the 26
            years history of the company, the year 2023 may be one of the bests
            if not the best! I would like to thank God, my co-workers and our
            clients/partners for making it possible. To you all, Merry Christmas
            and a prosperous 2023!
          </p>
        </div>
        <label for="check">Read More</label>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image {
  width: 100%;
  padding-bottom: 20px;
}
.title {
  font-family: "TT Commons", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 25%;
  width: 100%;
  text-align: left;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4e5152;
}
h4 {
  font-family: "TT Commons", sans-serif;
  color: #4e5152;
  font-size: 18px;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  text-align: justify;
}
#check{
  display: none;
}
.content{
  display: none;
  transition: 0.5s linear;
}
label{
  display: inline-block;
  color: #1A73E8;
  text-decoration: underline;
}
#check:checked ~ .content{
  display: block;
}
#check:checked ~label{
  visibility: hidden;
}
#check:checked ~ label::after{
  content: 'Show Less';
  display: block;
  visibility: visible;
  margin-top: -20px;
}
@media only screen and (max-width: 600px) {
  .title{
    font-size: 24px;
    top: 21%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
  .image{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h4{
    text-align: center;
  }
}
</style>
