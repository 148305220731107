<template>
  <header class="header-global">
    <base-nav class="navbar-main" type="" effect="dark" expand>
      <router-link slot="brand" class="navbar-brand" to="/">
        <img src="img/brand/logo/company-logo-tawi-group-holdings-inc.png" alt="logo" class="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="#">
            <img src="img/brand/logo/logo_tawi_big.svg" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav ml-lg-auto">
        <li class="nav-item">
          <a class="nav-link" href="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/industries">Industries We Serve</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/our-competencies">Our Competencies</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/sustainability">Sustainability</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/about-us">About Us</a>
        </li>
        <li class="nav-item">
          <b-button class="pallet-pooling">
            <a href="https://pallet-pooling.web.app/" target="_blank" class="pallet-pooling-link">
              <img src="img/icons/pallet-pooling.png" class="icon" alt="Pallet Pooling" />
              Pallet Pooling
            </a>
          </b-button>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
};
</script>

<style scoped>
.container {
  margin-left: 0% !important;
  margin-right: 0% !important;
}

.logo {
  height: 60px !important;
}

.navbar-nav {
  display: flex; 
  align-items: center; 
  margin: 0; 
  padding: 0; 
  list-style: none; 
}

.nav-item {
  display: flex;
  align-items: center; 
  margin: 0;
}

.nav-link {
  display: flex; 
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 550 !important;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #292931 !important;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
  color: #197cbd !important;
}

.pallet-pooling {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-color: #024F97;
  border-radius: 0.5vw;
  border-width: 0.5px;
  border-style: solid;
  box-shadow: none;
  padding: 0.3rem;
  padding-right: 0.5rem;
}

.pallet-pooling:hover {
  background-color: white;
  border-color: #122C91;
  color: #024F97;
}

.pallet-pooling-link {
  text-decoration: none;
  color: #024F97;
  text-transform: none;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.icon {
  height: 1.8em;
  width: auto;
  margin-right: 0.2rem;
}
</style>
