<template>
  <div>
    <img src="img/background/tawi-group-of-companies-trans-asia-wood-quadsys-easyhomes-tawitech-cesquare.jpg" class="img-fluid" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>