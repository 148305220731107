<template>
  <div>
    <section class="section-hero section-shaped my-0 background-image">
      <div class="shape shape-style-1">
        <!-- this serves additional space on the hero image section -->
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6"></div>
          <div class="col-lg-6">
            <h1 class="display-1 text-white lbl-main"></h1>
            <p class="display-5 text-white lbl-main-caption"></p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-3"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-6">
                <card class="border-0" hover body-classes="py-5">
                  <h2 class="lbl-main">TAWI Pallets</h2>
                  <p class="lbl-main-caption mt-4">
                    TAWI designed these pallets with the environment in mind.
                    Its use, reuse and care will help conserve our remaining
                    forests. All designs are registered under Philippines Patent
                    Office.
                  </p>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.background-image {
  background-image: url("/img/background/tawi-group-of-companies-products-01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>