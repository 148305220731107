<template>
  <div id="our-competencies">
    <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center mb-4">
          <div class="col-lg-8">
            <h2 class="lbl-main">Our Companies</h2>
            <p class="lbl-main-caption">
              TAWI Group of Companies is engaged in various businesses from
              wooden pallet manufacturing, wooden pallet supply, and wooden
              pallet rentals, plantation/sawmill, events catering,
              fabrication/installation of precast concrete products, and
              software development.
            </p>
          </div>
        </div>

        <div class="row adjusted-top-margin mb-4">
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <a href="https://www.facebook.com/TAWIGroup" target="_blank">
              <img
                v-lazy="
                  'img/brand/logo/company-logo-tawi-group-holdings-inc.png'
                "
                class="img-center img-fluid"
              />
            </a>

            <div class="lbl-company-name mt-1">
              ​​TAWI Group Holdings (est. 2019)
            </div>
            <div class="text-center mt-1 lbl-company-desc">
              Management of Group's Assets
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 mb-lg-0" id="trans-asia">
            <img
              v-lazy="'img/brand/logo/company-logo-trans-asia-wood-inc.png'"
              class="img-center img-fluid"
            />
            <div class="lbl-company-name mt-1">
              ​​Trans-Asia Wood Inc. (est. 1997)
            </div>
            <div class="text-center mt-1 lbl-company-desc">
              ​​TAWI has evolved from a humble manufacturer and supplier of
              one-time use wooden pallets to a vertically integrated pallet
              company. TAWI now has a sustainable tree plantation in Quezon,
              sawmill operations, 2 active wooden pallet manufacturing
              facilities, and a network of imported raw material suppliers.
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 mb-lg-0" id="quadsys">
            <a href="https://www.facebook.com/quadsysinc" target="_blank">
              <img
                v-lazy="'img/brand/logo/company-logo-quadsys-inc.png'"
                class="img-center img-fluid"
              />
            </a>
            <div class="lbl-company-name mt-1">
              ​​​​Quadsys Inc. (est. 2003)
            </div>
            <div class="text-center mt-2 lbl-company-desc">
              ​​It is the division of TAWI GROUP that is focused on providing
              competitive wooden pallet lease packages, technical solutions and
              after-sales services.QUADSYS pushes for continuous improvement in
              their products and services, and long-term, personalized
              partnerships with Clients.
            </div>
          </div>
          <div class="row adjusted-top-margin mb-4"></div>
          <div class="col-md-6 col-lg-4 mt-4 mb-5 mb-lg-0 " id="easyhomes">
            <a href="https://www.facebook.com/easyhomesinc.ph" target="_blank">
              <img
                v-lazy="'img/brand/logo/company-logo-easyhomes-inc.png'"
                class="img-center img-fluid"
              />
            </a>
            <div class="lbl-company-name mt-1">
              ​​​​​​Easyhomes Inc. (est. 2007)
            </div>
            <div class="text-center mt-2 lbl-company-desc">
              ​​​​EHI has fenced the developments of Alveo/Ayala, SM Prime
              Holdings, Belle Corporation, Landco, PA Builders, and CENQ. EHI
              also worked with MDC and DMWAI in fencing MCX and TPLEX
              expressways, respectively.The company with “Most Number of Patents
              Filed 1998-2017” - Intellectual Property Office.
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mt-4 mb-4 mb-lg-0 " id="tawitech">
            <a href="https://www.facebook.com/tawitechinc" target="_blank">
              <img
                v-lazy="'img/brand/logo/company-logo-tawitech-inc.png'"
                class="img-center img-fluid"
              />
            </a>
            <div class="lbl-company-name mt-1">
              ​​​​​​TAWItech Inc. (est. 2019)
            </div>
            <div class="text-center mt-2 lbl-company-desc">
              ​​TAWItech was established to develop applications that could
              solve challenges in the supply chain.TAWItech’s first product,
              Ayun!, is a revolutionary cloud-based asset monitoring, capable of
              monitoring movement of any asset within interconnected supply
              chains.
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mt-4 mb-4 mb-lg-0"  id="cesquare">
            <a
              href="https://www.facebook.com/bahayrestaurantPH"
              target="_blank"
            >
              <img
                v-lazy="'img/brand/logo/company-logo-ce-square.png'"
                class="img-center img-fluid"
              />
            </a>
            <div class="lbl-company-name mt-1">
              ​​CESquare (est. 2013)
            </div>
            <div class="text-center mt-2 lbl-company-desc">
              CE Square Incorporated was established as the leisure,
              hospitality, and property development arm of the group. It began
              operating with Bahay Restaurant in 2013 and has since grown the
              brand to be a successful events place, bed and breakfast, and
              restaurant in Mandaluyong.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        tawi: false,
        transasia: false,
        quadsys: false,
        easyhomes: false,
        tawitech: false,
        cesquare: false,
      },
    };
  },
  methods: {
    toggle(value) {
      this.options[value] = !this.options[value];
    },
  },
};
</script>

<style scoped>
.adjusted-top-margin {
  margin-top: 100px;
}

.lbl-company-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.lbl-company-desc {
  font-size: 15px !important;
  font-style: normal;
  letter-spacing: 0em;
  text-align: center;
  margin: 30px;
}

.link-show-more {
  cursor: pointer;
  color: #197cbd !important;
  font-size: 14px;
  font-style: italic;
}

.background-image {
  background-color: #f8f9fa;
}
img {
  width: 80%;
}
</style>
