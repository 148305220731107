<template>
  <section>
    <div>
      <img src="img/about-us/about-us.png" class="image-banner" />
    </div>
    <div class="row row-grid">
      <div class="col-md-2" id="our-story">
        <h2>OUR STORY</h2>
      </div>
      <div class="col-md-10">
        <p>
          <br /><br/>
          ​​Over the years, TAWI Group has evolved from a humble pallet
          manufacturing company into an agile multi-industry organization
          servicing our loyal clients while providing employment to our fellow
          Filipinos. TAWI has evolved from a humble manufacturer and supplier of
          one-time use wooden pallets to a vertically integrated pallet company.
          TAWI now has a sustainable tree plantation in Quezon, sawmill
          operations, 2 active wooden pallet manufacturing facilities, and a
          network of local and imported raw material suppliers.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  margin-top: -10%;
}
.title {
  font-family: "TT Commons", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: white;
  position: absolute;
  top:30%;
  width: 100%;
  text-align: left;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 15px;
  color: #4e5152;
  text-align: justify;
}
@media only screen and (max-width: 600px) {
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
    margin-top: -100px;
  }
}
</style>
