<template>
  <div>
    <Hero />
    <OurCompetencies/>

  </div>
</template>

<script>
import Hero from "./our-competencies/Hero.vue";
import OurCompetencies from "./our-competencies/OurCompetencies.vue";

export default {
  components: {
    Hero,
    OurCompetencies
},
};
</script>

<style scoped>
</style>