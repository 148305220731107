<template>
    <section>
      <div id="history">
        <img src="img/about-us/tawi-timeline.svg" class="image-banner" />
      </div>
    </section>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  @import url("https://fonts.cdnfonts.com/css/tt-commons");
  .image-banner {
    position: relative;
    width: 100%;
    margin-right: -10%;
  }
  </style>
  