<template>
  <section id="csr" class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-1 background-image">
          <img
            src="img/products/pallets/tawi-plastic-pallet-black.png"
            class="img-fluid floating img-dimen"
          />
        </div>
        <div class="col-md-6 order-md-2">
          <div class="pr-md-6">
            <h2 class="lbl-main">Plastic Pallet</h2>

            <div class="lbl-header">Dimension</div>
            <div class="lbl-section">
              1200 x 1000 x 160 mm <br />
              (customizable)
            </div>

            <div class="lbl-header">Specification</div>
            <div class="lbl-section">4-way, Non-reversible</div>

            <div class="lbl-header">Load Capacity</div>
            <div class="lbl-section">
              Static Load: 3000 kgs. <br />
              Dynamic Load: 1500 kgs. <br />
              Racking Load: 1200 kgs. <br />
            </div>

            <div class="lbl-header">Material</div>
            <div class="lbl-section">
              HDPE (High Density Polyethylene) RECYCLED MATERIAL <br />
              Non-Reversible; Deck Type; Perforated Face; Heavy Duty; <br />
              Four (4) Way Forklift / Hand Pallet
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.adjust-top-margin {
  margin-top: -140px;
}

.background-image {
  background-color: #f1e4ff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-dimen {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>