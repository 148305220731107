export default {
    currEnv: 'prod',
    companyEmail: 'support@tawi.com.ph',
    companyContactNo: '0917 111 8008',
    eLogLink: 'https://elog.ph/',
    easyhomesLink: 'https://easyhomes.ph/',
    tawiFbLink: 'https://www.facebook.com/TAWIGroup',
    tawiLinkedinLink: 'https://www.linkedin.com/company/tawigroup/',
    tawiYTLink: 'https://www.youtube.com/channel/UCpWJDJHEzXQPKd6r6SQ01ag',
    tawiIGLink: 'https://www.instagram.com/tawigroup/'
}