<template>
  <section id="csr" class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2 background-image">
          <img
            src="img/products/pallets/tawi-wooden-pallet-collapsible.png"
            class="img-fluid floating img-dimen"
          />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-6">
            <h2 class="lbl-main">Collapsible Pallet</h2>

            <div class="lbl-header">Dimension</div>
            <div class="lbl-section">1200 x 1000 x 156 mm (customizable)</div>

            <div class="lbl-header">Specification</div>
            <div class="lbl-section">
              4-way, Non-reversible, semi-assembled components with male-female
              connection details secured by industrial straps, painted perimeter
              with TAWI markings and QR tags
            </div>

            <div class="lbl-header">Load Capacity</div>
            <div class="lbl-section">750-1,000 kgs</div>

            <div class="lbl-header">Treatment</div>
            <div class="lbl-section">Vacuum Pressure Treatment</div>

            <div class="lbl-header"></div>
            <div class="lbl-section">
              *used for multi-use inter-island or cross-border transfers for
              cost-effective forward and reverse logistics
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.background-image {
  background-color: #f1e4ff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-dimen {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>