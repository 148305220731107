<template>
  <div>
    <Hero />
    <ConsumerProducts/>
    <WhyTawi/>
    <IndustriesWeServe/>
  </div>
</template>

<script>
import IndustriesWeServe from "./industries/IndustriesWeServe.vue";
import ConsumerProducts from "./industries/ConsumerProducts.vue";
import Hero from "./industries/Hero.vue";
import WhyTawi from "./industries/WhyTawi.vue";

export default {
  components: {
    Hero,
    ConsumerProducts,
    IndustriesWeServe,
    WhyTawi
},
};
</script>

<style scoped>
</style>