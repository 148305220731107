<template>
  <section>
    <div class="row">
      <div class="col-md-6">
        <h2>Why TAWI Pallets?</h2>
        <p>
          The benefits of working with us.
        </p>
      </div>
      <div class="col-md-6">
        <ul>
        <li>
          Years of reliable and sustainable experience.
          <p>
            TAWI has been in the supply chain industry for more than 25 years in
            the market. It has served and supported clients from various
            industries like FMCG, Beverages, Food, Pharmaceuticals, Third-Party
            Logistics (3PL’s), Construction Materials, etc.
          </p>
        </li>
        <li>
          TAWI wooden pallets are trackable.
          <p>
            Worry no more about lost pallets in your supply chain. TAWI pallets
            are now trackable by TAWI’s asset monitoring system Ayun!. Ayun! is
            a supply chain-wide asset monitoring system used for tracking moving
            assets within the supply chain. It utilizes QR codes and cloud
            technology to transfer and track the accountability of assets within
            your supply chain.
          </p>
        </li>
        <li>
          Proactive Customer Service
          <p>
            TAWI provides customer support and assistance in classification and
            repairs, pallet care trainings, inventory, additional pallet
            deliveries, BER replacements, billing concerns, monthly digital
            business review report, and many more.
          </p>
        </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 100px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 18px;
  color: #4e5152;
  text-align: justify;
}
li {
  font-family: "TT Commons", sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  color: #4e5152;
  padding:  0 0  0 30px;
  list-style-type: none;
  background-image:url("/img/industries/checkbox.png");
  background-repeat: no-repeat;
  background-size: 25px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  section{
    margin-left: 60px;
    margin-right: 60px;
  }
  li{
    font-size: 16px;
    font-weight: bold;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
}
</style>
