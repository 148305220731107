<template>
  <section id="csr" class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2 background-image">
          <img
            src="img/products/pallets/tawi-carton-pallet-trans.png"
            class="img-fluid floating img-dimen"
          />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-6">
            <h2 class="lbl-main">Carton Pallet</h2>

            <div class="lbl-header"></div>
            <div class="lbl-section">
              ISPM 15 exempt <br />
              Eco-friendly <br />
              Up to 1000kg load capacity <br />
              More cost-effective <br />
              Lightweight <br />
              Customizable <br />
              Safer operations <br />
            </div>

            <div class="lbl-header">Readily available dimensions</div>
            <div class="lbl-section">
              1m x 1m <br />
              1.1m x 1.1m <br />
              1m x 1.2m <br />
            </div>

            <div class="lbl-header"></div>
            <div class="lbl-section">
              *used in international exports to reduce freight cost without
              sacrificing carrying capacity
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.background-image {
  background-color: #f1e4ff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-dimen {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>