<template>
    <section>
      <div>
        <h2>
            Our Locations
        </h2>
        <img src=img/background/our-locations.svg class="image"/>
      </div>
    </section>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  @import url("https://fonts.cdnfonts.com/css/tt-commons");
  section {
    margin: 100px;
  }
  h2 {
    font-family: "TT Commons", sans-serif;
    font-weight: bold;
    font-size: 26px;
    color: #4e5152;
  }
  .image {
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    section{
      margin: 20px;
    }
  }
  </style>
  