<template>
  <section>
    <div>
      <img src="img/industries/industries-we-serve.png" class="image-banner" />
    </div>
    <div>
      <h2>
        Secure and maximize your assets
      </h2>
      <p>
        TAWI Group of Companies is engaged in various businesses from wooden
        pallet manufacturing, wooden pallet supply, and wooden pallet rentals,
        plantation/sawmill, events catering, fabrication/installation of precast
        concrete products, and software development.
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 50px;
}
.image-banner {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  margin-top: -10%;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  color: #4E5152;
}
p{
  font-family: "TT Commons", sans-serif;
  font-size: 16px;
  color: #4E5152;
  text-align: justify;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .title{
    font-size: 24px;
    top: 18%;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
}
</style>
