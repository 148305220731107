<template>
  <div>
    <Hero />
    <TawiTimeline/>
    <MessageFromTheFounder/>
    <CoreValues/>
  </div>
</template>

<script>
import Hero from "@/views/about-us/Hero";
import CoreValues from "./about-us/CoreValues.vue";
import MessageFromTheFounder from "./about-us/MessageFromTheFounder.vue";
import TawiTimeline from "./about-us/TawiTimeline.vue";



export default {
  components: {
    Hero,
    TawiTimeline,
    MessageFromTheFounder,
    CoreValues
}
};
</script>

<style scoped>
.lbl-main {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
}
</style>