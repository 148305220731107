<template>
  <section id="csr" class="section section-lg adjust-top-margin">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-1 background-image">
          <img
            src="img/products/pallets/tawi-wooden-pallet-ecr.png"
            class="img-fluid floating img-dimen"
          />
        </div>
        <div class="col-md-6 order-md-2">
          <div class="pr-md-6">
            <h2 class="lbl-main">ECR Pallet</h2>

            <div class="lbl-header">Dimension</div>
            <div class="lbl-section">1200 x 1000 x 156mm</div>

            <div class="lbl-header">Specification</div>
            <div class="lbl-section">
              4-way, Non-reversible; Painted-Perimeter with serial number and
              date with QR tags in all 4 sides
            </div>

            <div class="lbl-header">Load Capacity</div>
            <div class="lbl-section">
              Static Load – 4,000 kg <br />
              Dynamic – 2,000 kg
            </div>

            <div class="lbl-header">Treatment</div>
            <div class="lbl-section">Vacuum Pressure Treatment</div>

            <div class="lbl-header"></div>
            <div class="lbl-section">
              *mostly used in ecommerce, FMCG, retail, export, logistics
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.adjust-top-margin {
  margin-top: -140px;
}

.background-image {
  background-color: #f1e4ff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-dimen {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>