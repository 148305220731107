<template>
  <section id="csr" class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center"  id="our-story">
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <h2 class="lbl-main">OUR STORY</h2>
            <p class="lbl-main-caption">
              Over the years, TAWI Group has evolved from a humble pallet
              manufacturing company into an agile multi-industry organization
              servicing our loyal clients while providing employment to our
              fellow Filipinos.
            </p>
            <p class="lbl-main-caption">
              We are the only vertically-integrated pallet service provider in
              the country, if not, in the whole ASEAN region. Trans-Asia Wood
              Incorporated has been planting and maintaining trees for more than
              20years. 100% of our repair lumber requirements are sourced from
              our 500-hectare plantation in Quezon province.
            </p>
            <p class="lbl-main-caption">
              From then until now, wooden pallets have been the way to transfer
              goods from one place to another. It has become one of the major
              packaging equipment in the supply chain industry. This has paved
              the way for multiple pallet variations across the globe. There are
              millions of pallets in use every day, and more than 90% of them
              are made from wood.
            </p>
            <b-button href="/about-us" class="button">THE TAWI DIFFERENCE</b-button>
          </div>
        </div>
        <div class="col-md-6 order-md-2">
          <img
            src="img/background/our-story.svg"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/tt-commons');
h2{
    font-family: 'TT Commons', sans-serif;
    font-size: 24px;
}
p{
    font-size: 14px;
    font-family: 'TT Commons', sans-serif;
    color: #4E5152;
    text-align: justify;                                    
}
.button{
    font-family: 'TT Commons', sans-serif;
    background-color: white;
    color: #07529C;
    border-radius: 0;
    font-weight: bold;
    font-size: 10px;
}
.button:hover{
  color: white;
  background-color: #07529C;
}
</style>
