<template>
  <div>
    <section class="section section-lg background-image">
      <div class="container">
        <div class="row justify-content-center text-center mb-lg">
          <div>
            <h2 class="lbl-main">OUR CLIENTS</h2>
            <p class="lbl-main-caption">
              Here are some of the industries we are proud to serve throughout
              the Philippines.
            </p>
          </div>
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            img-width="1024"
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              img-src="img/clients/q-clients.png"
            ></b-carousel-slide
            ><b-carousel-slide
              img-src="img/clients/ehi-clients.png"
            ></b-carousel-slide
          ></b-carousel>
        </div>
        <!--
        <div class="row mb-4 adjust-top-margin">
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-coca-cola.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-dhl-express.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-lbc-express.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-sc-johnson-and-son-inc.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-gentle-supreme-philippines.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-metro-combined-logistics-solutions-inc.png'
              "
              class="img-center img-fluid"
            />
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-pepsi.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-sysu-international-inc.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-cosmos-bottling-corporation.png'
              "
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-polysack-corporation.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-consolidated-dairy-and-frozen-food-corporation.png'
              "
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-sendiva-logistics.png'"
              class="img-center img-fluid"
            />
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-first-philippine-silkroad-international-corporation.png'
              "
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-philip-morris-international-inc.png'
              "
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-froneri-ice-cream.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-kuehne-+-nagel.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="
                'img/clients/company-logo-blue-mountain-world-distribution-incorporated.png'
              "
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-rfm-corporation.png'"
              class="img-center img-fluid"
            />
        </div>
      </div>

      <div class="row mt-4 mb-4">
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-logistikus-express-inc.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-eagle-cement-corporation.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-boysen-the-no.-1-paint.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-future-trade-international.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-pure-logistics-express-inc.png'"
              class="img-center img-fluid"
            />
          </div>
          <div class="col-md-6 col-lg-2 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-2go-travel.png'"
              class="img-center img-fluid"
            />
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-md-6 col-lg-1 mb-5 mb-lg-0"></div>
          <div class="col-md-6 col-lg-1 mb-5 mb-lg-0"></div>
          <div class="col-md-6 col-lg-1 mb-5 mb-lg-0"></div>
          <div class="col-md-6 col-lg-6 mb-5 mb-lg-0">
            <img
              v-lazy="'img/clients/company-logo-air-21-sagot-ko-padala-mo.png'"
              class="img-center img-fluid"
            />
          </div>
        </div>
-->
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section{
  margin-bottom: -170px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-size: 24px;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 16px;
  color: #4e5152;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.adjust-top-margin {
  margin-top: -50px;
}
.background-image {
  background-color: white;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px !important;
  width: 100px !important;
}
</style>
