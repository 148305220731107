<template>
  <footer class="footer background-image">
    <div class="container container-lg">
      <div>
        <ContactUs />
      </div>
      <div>
        <p>
          In addition to this inquiry, TAWI GROUP HOLDINGS INC. would like to
          keep in contact with you in the future regarding products/services
          that you may be interested in. Please be assured your information will
          be treated with the utmost care and we will never share your details
          outside our company.
        </p>
      </div>
      <div class="row">
        <div class="col-md-6">
          <img src="img/brand/logo/logo_tawi_big.svg" class="logo" />
          <br /><br />
          <h2>GET IN TOUCH</h2>
          <h3>We'd love to hear from you!</h3>
        </div>
        <div class="col-md-6">
          <h4>OFFICE ADDRESS</h4>
          <h5>
            TAWI Compound, Pulo-Diezmo Road,<br />Brgy. Pulo, Cabuyao, Laguna
            4025
          </h5>
          <br/><br/>
          <h4>PHONE NUMBER</h4>
          <h5>
            (+632) 8519 4042 | (+6349) 543 0598<br />
            (+63) 917 7037 401 - Luzon | (+63) 919 0041 162 - VisMin
          </h5>
          <br/><br/>
          <h4>EMAIL ADDRESS</h4>
          <h5>
            support@tawi.com.ph
          </h5>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import config from "@/config/env-constants";
import ContactUs from "@/components/ContactUs";

export default {
  name: "app-footer",
  components: {
    ContactUs,
  },
  data() {
    return {
      tawiFbLink: config.tawiFbLink,
      tawiLinkedinLink: config.tawiLinkedinLink,
      tawiYTLink: config.tawiYTLink,
      tawiIGLink: config.tawiIGLink,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
p {
  font-family: "TT Commons", sans-serif;
  margin: 70px;
  text-align: center;
}
.lbl-section-name {
  margin-top: 40px !important;
}

.lbl-section-contact {
  margin-top: 10px !important;
}
.background-image {
  /**background-image: url("/img/background/bottom_background.svg");**/
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f8f9fa;
}
.logo {
  width: 300px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  color: #315187;
  font-size: 25px;
  margin-top: 35px;
}
h3 {
  font-family: "TT Commons", sans-serif;
  color: #4e5152;
  font-size: 20px;
}
h4 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  color: #4e5152;
  font-size: 16px;
}
h5 {
  font-family: "TT Commons", sans-serif;
  color: #4e5152;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  p{
    font-size: 14px;
  }
}
</style>
