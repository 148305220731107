<template>
  <footer class="footer background-image">
    <div class="container">
      <div class="row">
        <div class="banner">
          <img src="img/landing/need-help.png" class="need-help" /><!--
          <h2 class="heading">NEED HELP IN YOUR NEW BUSINESSS?</h2>
          <p class="help-body">
            We’d love to hear from you!<br /><br />
            Schedule a presentation to learn about our services and products. We
            will come to you, or host a meeting at our facility to deliver an
            educational presentation on our services and solutions for a variety
            of applications. A plant tour completes the experience to reveal why
            TAWI has been in the industry for 25 years.
          </p>-->
          <b-button href="/contact-us" class="button">CONTACT US TODAY!</b-button>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <img src="img/brand/logo/logo_tawi_big.svg" class="logo" />
    </div>
    <section>
      <div class="row">
        <div class="col md-4">
          <h3>
            OFFICE ADDRESS
          </h3>
          <p>
            {{ getDetails('Office Address') }}
          </p>
        </div>
        <div class="col md-4">
          <h3>PHONE NUMBER</h3>
          <p>
            {{ getDetails('Telephone Number 1') }} | {{ getDetails('Telephone Number 2') }}<br />
            {{ getDetails('Mobile Number 1') }} | {{ getDetails('Mobile Number 2') }}
          </p>
        </div>
        <div class="col md-4">
          <h3>EMAIL ADDRESS</h3>
          <p>{{ getDetails('E-mail Address') }}</p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-3">
          <h2 class="lbl-small mt-0">
            <a href="/" class="link">HOME</a>
          </h2>
          <h2 class="lbl-small mt-0">
            <a href="/industries" class="link">INDUSTRIES WE SERVE</a>
          </h2>
          <li>
            <a href="/industries#consumer-products" class="footer-links">Consumer Products</a>
          </li>
          <li>
            <a href="/industries#retail-food-and-beverages" class="footer-links">Retail Food and Beverages</a>
          </li>
          <li>
            <a href="/industries#industrial-parks" class="footer-links">Industrial Parks</a>
          </li>
          <li>
            <a href="/industries#construction-contractors" class="footer-links">Construction Contractors</a>
          </li>
          <li>
            <a href="/industries#real-estate-developments" class="footer-links">Real Estate Developments</a>
          </li>
          <li>
            <a href="/industries#infrastructure-projects" class="footer-links">Infrastructure Projects</a>
          </li>
          <li>
            <a href="/industries#logistics" class="footer-links">Logistics</a>
          </li>
          <li>
            <a href="/industries#eco-tourism" class="footer-links">Eco-Tourism</a>
          </li>
          <li><a href="/industries#events" class="footer-links">Events</a></li>
        </div>
        <div class="col-md-3">
          <h2 class="lbl-small mt-0">
            <a href="/our-competencies" class="link">OUR COMPETENCIES</a>
          </h2>
          <li>
            <a href="/our-competencies#pallet-leasing-and-sales" class="footer-links">Pallet Leasing & Sales
            </a>
          </li>
          <li>
            <a href="/our-competencies#precast-concrete-construction" class="footer-links">Precast Concrete
              Construction</a>
          </li>
          <li>
            <a href="/our-competencies#asset-management-solutions" class="footer-links">Asset Management Solutions
            </a>
          </li>
          <li>
            <a href="/our-competencies#digital-logging-system" class="footer-links">Digital Logging System
            </a>
          </li>
          <li>
            <a href="/our-competencies#warehousing-software-solutions" class="footer-links">Warehousing Software
              Solutions</a>
          </li>
          <li>
            <a href="/our-competencies#security-solutions" class="footer-links">Security Solutions</a>
          </li>
          <li>
            <a href="/our-competencies#it-solutions-hardware" class="footer-links">IT Solutions / Hardware</a>
          </li>
          <li>
            <a href="/our-competencies#agro-tourism" class="footer-links">Agro-Tourism</a>
          </li>
          <li>
            <a href="/our-competencies#events" class="footer-links">Events</a>
          </li>
          <br />
          <h2 class="lbl-small mt-0">
            <a href="/sustainability" class="link">SUSTAINABILITY</a>
          </h2>
          <li>
            <a href="/sustainability#tree-planting" class="footer-links">Lumber Plantation
            </a>
          </li>
        </div>
        <div class="col-md-3">
          <h2 class="lbl-small mt-0">
            <a href="/about-us" class="link">ABOUT US</a>
          </h2>
          <li>
            <a href="/about-us#our-story" class="footer-links">Our Story</a>
          </li>
          <li><a href="/about-us#history" class="footer-links">History</a></li>
          <li>
            <a href="/about-us#message-from-the-founder" class="footer-links">Message from the Founder</a>
          </li>
          <li>
            <a href="/about-us#core-values" class="footer-links">Core Values</a>
          </li>
          <br />
          <h2 class="lbl-small mt-0">
            <a href="/companies" class="link">COMPANIES</a>
          </h2>
          <li>
            <a href="/companies#trans-asia" class="footer-links">Trans-asia Wood Inc. </a>
          </li>
          <li>
            <a href="/companies#quadsys" class="footer-links">Quadsys Inc. </a>
          </li>
          <li>
            <a href="/companies#easyhomes" class="footer-links">Easyhomes Inc.
            </a>
          </li>
          <li>
            <a href="/companies#tawitech" class="footer-links">Tawitech Inc.
            </a>
          </li>
          <li>
            <a href="/companies#cesquare" class="footer-links">CE Square Inc.
            </a>
          </li>
          <br />
          <h2 class="lbl-small mt-0">
            <a href="/innovation-and-quality" class="link">INNOVATION AND QUALITY COMMITMENT</a>
          </h2>
        </div>
        <div class="col-md-3">
          <h2 class="lbl-small mt-0">
            <a href="/blogs" class="link">BLOGS</a>
          </h2>
          <li>
            <a href="/blogs" class="footer-links">Newsletter Articles </a>
          </li>
          <br />
          <h2 class="lbl-small mt-0">
            <a href="/our-people" class="link">OUR PEOPLE</a>
          </h2>
          <li>
            <a href="/our-people#employee-testimonials" class="footer-links">Employee Testimonials
            </a>
          </li>
          <li>
            <a href="/csr" class="footer-links">Employee Advocacy Program </a>
          </li>
          <li>
            <a href="/our-vacancies" class="footer-links">Our Vacancies </a>
          </li>
          <br />
          <h2 class="lbl-small mt-0">
            <a href="/contact-us" class="link">CONTACT US</a>
          </h2>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <router-link to="/">
            <img src="img/brand/logo/logo_tawi_big.svg" class="logo-small" />
          </router-link>
          <span class="ml-1">&copy; {{ currentYear }} </span>
        </div>
        <div class="col-md-6">
          <a :href="tawiFbLink" target="_blank">
            <img src="img/icons/facebook.png" class="social-icon" alt="Facebook" /></a>
          <a :href="tawiLinkedinLink" target="_blank">
            <img src="img/icons/linkedin.png" class="social-icon" alt="LinkedIn" /></a>
          <a :href="tawiYTLink" target="_blank">
            <img src="img/icons/youtube.png" class="social-icon" alt="Youtube" /></a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
// Component
import ContactUs from "@/components/ContactUs";

// API & DAO
import contactsDAO from '@/database/salesContact';

// Others
import config from "@/config/env-constants";
import _ from 'lodash';

export default {
  name: "app-footer",
  components: {
    ContactUs,
  },
  data() {
    return {
      allContactsObj: {},
      tawiFbLink: config.tawiFbLink,
      tawiLinkedinLink: config.tawiLinkedinLink,
      tawiYTLink: config.tawiYTLink,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  async mounted() {
    await this.getAllSalesContacts();
  },
  methods: {
    async getAllSalesContacts() {
      this.allContactsObj = await contactsDAO.retrieveAll();
    },
    getDetails(contactType) {
      const details = _.find(this.allContactsObj, obj => obj.contactType === contactType);
      return details ? details.details : 'Not found';
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");

h3 {
  font-family: "TT Commons", sans-serif;
  color: #4e5152;
  font-weight: bold;
  font-size: 18px;
}

li {
  list-style-type: none;
}

.need-help {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -51vw;
  margin-right: -51vw;
  max-width: 100vw;
  width: 100vw;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.logo-small {
  height: 80px;
}

.banner {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.social-icon {
  height: 40px;
  margin: 5px;
  float: right;
}

.button {
  font-family: "TT Commons", sans-serif;
  background-color: white;
  border-radius: 10px;
  color: #07529c;
  font-weight: bold;
  font-size: 1.5vw;
  text-align: center;
  top: -25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.footer-links {
  font-family: "TT Commons", sans-serif;
  text-decoration: none;
  font-size: 16px;
  margin-top: 30px !important;
  color: #4e5152;
}

.footer-links:hover {
  text-decoration: none;
  color: #197cbd !important;
}

.link {
  text-decoration: none;
  color: #4e5152;
  font-size: 16px;
}

.link:hover {
  text-decoration: none;
  color: #197cbd !important;
}

.background-image {
  /**background-image: url("/img/background/bottom_background.svg");**/
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: white;
  margin: 40px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .button {
    top: -45px;
  }

  .social-icon {
    height: 30px;
    margin: 5px;
    margin-top: 20px;
    float: left;
  }
}
</style>
