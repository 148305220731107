<template>
  <section>
    <div class="row row-grid align-items-center" id="consumer-products">
      <div class="col-md-6">
        <img src=img/industries/consumer-products.svg class="image"/>
      </div>
      <div class="col-md-6">
        <h2>Consumer Products</h2>
        <div>
          <p>
            We serve the consumer goods sector consists of companies that
            manufacture and sell products for consumer use.
          </p>
          <b-button href="/contact-us" class="button">INQUIRE NOW</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/tt-commons");
section {
  margin: 100px;
}
h2 {
  font-family: "TT Commons", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  margin-left: 100px;
  color: #4e5152;
}
p {
  font-family: "TT Commons", sans-serif;
  font-size: 18px;
  margin-left: 200px;
  color: #4e5152;
  text-align: justify;
}
.image {
  width: 100%;
}
.button {
  font-family: "TT Commons", sans-serif;
  background-color: white;
  border-color: #a7adaf;
  border-radius: 0;
  font-weight: bold;
  font-size: 10px;
  color: #07529c;
  margin-left: 200px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  section{
    margin-left: 60px;
    margin-right: 60px;
  }
  h2{
    margin-left: 0px;
    margin-top: -20px;
    font-size: 20px;

  }
  p{
    margin: 0px;
    font-size: 14px;
  }
  .button{
    margin-left: 0px;
  }
}
</style>
