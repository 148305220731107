import { db } from "@/config/firebase";

async function retrieveAll() {
    let query = db.collection("salesContacts");

    const querySnapshot = await query.get();

    let results = [];
    querySnapshot.forEach((doc) => {
        results.push({
            id: doc.id,
            ...doc.data(),
        });
    });

    return results;
}

export default {
    retrieveAll
}